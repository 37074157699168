
import {useCallback, useEffect, useMemo, useState} from 'react';

import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {SCOPE_TYPE} from './consts';
import {getUserInfo} from './integration';

import {getGroup, getTeams, getUser} from 'Api/ZCalendar';
import {strEq} from 'Utils';

export const getScopeFromQuery = (searchParams) => {
  const self = getUserInfo();
  let queryScope = {
    id: self.userId,
    name: self.userName,
    email: self.loginEmail,
    type: SCOPE_TYPE.USER,
  };
  const queryUser = searchParams.get('userId');
  const queryGroup = searchParams.get('groupId');
  const queryTeam = searchParams.get('teamId');
  if (queryUser) {
    queryScope = {
      id: queryUser,
      type: SCOPE_TYPE.USER,
    };
  } else if (queryGroup) {
    queryScope = {
      id: queryGroup,
      type: SCOPE_TYPE.GROUP,
    };
  } else if (queryTeam) {
    queryScope = {
      id: queryTeam,
      type: SCOPE_TYPE.TEAM,
    };
  }
  return queryScope;
};

export const getCalendarFromScope = (scope) => {
  const self = getUserInfo();
  if (self?.isCciAccount) {
    return `contact_center_${self?.accountId}@group.scheduler.zoom.us`;
  }
  switch (scope.type) {
    case SCOPE_TYPE.USER:
      return `${scope.id}@scheduler.zoom.us`;
    case SCOPE_TYPE.TEAM:
      return `team_${scope.id}@scheduler.zoom.us`;
    default:
      break;
  }
};

export const getQueryFromScope = (scope) => {
  switch (scope.type) {
    case SCOPE_TYPE.USER:
      return {userId: scope.id};
    case SCOPE_TYPE.TEAM:
      return {teamId: scope.id};
    default:
      break;
  }
};

/**
 * Hook to manage scope state
 * @param {boolean} syncSearchParams ?userId=xxx in address bar
 * @param {boolean} queryScopeInfo query scope info when setSelectedScope executed
 * @return {{
 *  selectedScope: Scope,
 *  scopeLoading: boolean,
 *  setSelectedScope: function,
 * }}
 */
export const useScope = (syncSearchParams = true, queryScopeInfo = true) => {
  const {isCciAccount, loginEmail, userId: uid, userName} = getUserInfo() || {};
  const selfDisplayName = useSelector((state) => state.hostSettingsState.displayName.value);

  const [searchParams, setSearchParams] = useSearchParams();
  const queryUser = searchParams.get('userId');
  const queryGroup = searchParams.get('groupId');
  const queryTeam = searchParams.get('teamId');

  const enableScopeList = !isCciAccount;
  const queryScope = useMemo(() => {
    // if disabled, always use scope 'self'
    let queryScope = {
      id: uid,
      name: selfDisplayName || userName,
      email: loginEmail,
      type: SCOPE_TYPE.USER,
    };
    if (enableScopeList) {
      if (queryUser) {
        queryScope = {
          id: queryUser,
          type: SCOPE_TYPE.USER,
        };
      } else if (queryGroup) {
        queryScope = {
          id: queryGroup,
          type: SCOPE_TYPE.GROUP,
        };
      } else if (queryTeam) {
        queryScope = {
          id: queryTeam,
          type: SCOPE_TYPE.TEAM,
        };
      }
    }
    return queryScope;
  }, [uid, userName, loginEmail, enableScopeList, queryUser, queryGroup, queryTeam, selfDisplayName]);

  const [selectedScope, setSelectedScope] = useState(queryScope);

  const wrappedSetScope = useCallback((newScope) => {
    if (syncSearchParams) {
      setSearchParams((prev) => {
        const query = {...prev};
        if (newScope.type === SCOPE_TYPE.USER) {
          query.userId = newScope.id;
        } else if (newScope.type === SCOPE_TYPE.GROUP) {
          query.groupId = newScope.id;
        } else if (newScope.type === SCOPE_TYPE.TEAM) {
          query.teamId = newScope.id;
        }
        return query;
      });
    }
    setSelectedScope(newScope);
  }, [setSearchParams, syncSearchParams]);

  const [scopeLoading, setScopeLoading] = useState(false);

  useEffect(() => {
    const getters = {
      [SCOPE_TYPE.USER]: getUser,
      [SCOPE_TYPE.GROUP]: getGroup,
      [SCOPE_TYPE.TEAM]: getTeams,
    };
    let isNotStale = true;
    if (queryScopeInfo && selectedScope &&
        ((selectedScope.type === SCOPE_TYPE.USER && !selectedScope.email) ||
        (selectedScope.type === SCOPE_TYPE.TEAM && selectedScope.slug === undefined))
    ) {
      setScopeLoading(true);
      const getter = getters[selectedScope.type];
      getter(selectedScope.id)
        .then((data) => {
          if (isNotStale) {
            setScopeLoading(false);
            const scope = {
              ...selectedScope,
              name: data.name,
              email: data?.email,
              slug: data?.slug,
            };
            if (selectedScope.type === SCOPE_TYPE.TEAM) {
              scope.isTeamAdmin = (data.admins ?? []).some((admin) => strEq(admin.id, uid));
            }
            setSelectedScope(scope);
          }
        })
        .catch((e) => {
          if (isNotStale) {
            setScopeLoading(false);
            setSearchParams();
            if (!!uid) {
              setSelectedScope({
                id: uid,
                name: userName,
                email: loginEmail,
                type: SCOPE_TYPE.USER,
              });
            }
          }
        });
    }
    return () => {
      isNotStale = false;
    };
  }, [queryScopeInfo, selectedScope, uid, userName, loginEmail, setSearchParams]);
  const res = useMemo(() => (
    {
      scopeLoading,
      selectedScope,
      setSelectedScope: wrappedSetScope,
    }
  ), [scopeLoading, selectedScope, wrappedSetScope]);
  return res;
};
