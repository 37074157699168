import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  getTeams,
  createTeam,
  patchTeam,
  deleteTeam,
  getTeamMembers,
  addTeamMember,
  deleteTeamMember,
} from 'Api/ZCalendar';

export const addTeam = createAsyncThunk(
  'teamStore/addTeam',
  async (data) => {
    const response = await createTeam(data);
    return response;
  }
);

export const editTeam = createAsyncThunk(
  'teamStore/editTeam',
  async (team) => {
    const response = await patchTeam(team);
    return response;
  }
);

export const removeTeam = createAsyncThunk(
  'teamStore/removeTeam',
  async (teamId) => {
    const response = await deleteTeam(teamId);
    return response;
  }
);

export const listTeams = createAsyncThunk(
  'teamStore/listTeams',
  async (data, thunkAPI) => {
    const response = await getTeams();
    return response;
  }
);

export const listTeamMembers = createAsyncThunk(
  'teamStore/listTeamMembers',
  async (data, thunkAPI) => {
    const teamId = data.id;
    const response = await getTeamMembers(teamId);
    return response;
  }
);

export const assignTeamMember = createAsyncThunk(
  'teamStore/assignTeamMember',
  async (data) => {
    const response = await addTeamMember(data);
    return response;
  }
);

export const removeTeamMember = createAsyncThunk(
  'teamStore/removeTeamMember',
  async (data) => {
    const response = await deleteTeamMember(data);
    return response;
  }
);

export const teamState = createSlice({
  name: 'teamStore',
  initialState: {
    teams: [],
    members: [],
    managedTeams: [],
    memberTeams: [],
    loading: false,
    errors: {
      members: false,
    },
  },
  reducers: {
    setTeamLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listTeams.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(listTeams.fulfilled, (state, action) => {
      state.teams = action.payload.teams;
      state.loading = false;
    });
    builder.addCase(listTeams.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(listTeamMembers.pending, (state, action) => {
      state.loading = true;
      state.errors.members = false;
      state.members = [];
    });
    builder.addCase(listTeamMembers.fulfilled, (state, action) => {
      state.members = action.payload.members;
      state.loading = false;
    });
    builder.addCase(listTeamMembers.rejected, (state, action) => {
      state.loading = false;
      state.errors.members = true;
    });
    builder.addCase(addTeam.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addTeam.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addTeam.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editTeam.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editTeam.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editTeam.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeam.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeTeam.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeam.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignTeamMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignTeamMember.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeamMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeTeamMember.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {setTeamLoading} = teamState.actions;

export default teamState.reducer;
