import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {validateHostConnections} from 'Api/ZCalendar';
import {getVirtualUserEmail} from 'Utils';
import {getUserInfo} from 'Utils/integration';

const initialState = {
  'boundEmail': {},
  'healthResults': {},
  'isLoading': false,
  'errorState': {
    isError: false,
    reason: '',
  },
};

/**
 * Selector to determine if the logged-in user has an account validation error
 * @param {*} state
 * @param {HostAttendee[]} attendees
 * @return {boolean}
 */
export const selectCurrUserHasError = (state) => {
  const selfCalId = getUserInfo()?.calendarId;
  const connHealthState = state.hostConnectionHealthState.healthResults;
  const hasValidationErr = connHealthState[selfCalId.toLowerCase()]?.isError;
  return hasValidationErr;
};

/**
 *
 * @param {*} healthResults
 * @param {OrgUser} orgUser
 * @return {boolean}
 */
export const selectUserHasLicenseErr = (healthResults, orgUser) => {
  const res = healthResults[getVirtualUserEmail(orgUser.userId).toLowerCase()];
  return res?.isError && (res?.reason?.match(/license/));
};

/**
 * Selector to determine any of the given host attendees have validation errors
 * @param {*} state
 * @param {OrgUser[]} members
 * @return {boolean}
 */
export const selectAnyMembersHaveLicenseError = (state, members) => {
  const orgUsers = members || [];
  const connHealthState = state.hostConnectionHealthState.healthResults;
  const hasLicenseError = orgUsers.some((orgUser) => selectUserHasLicenseErr(connHealthState, orgUser));
  return hasLicenseError;
};

/**
 *
 * @param {*} healthResults
 * @param {OrgUser} orgUser
 * @return {boolean}
 */
export const selectUserHasUninitializedErr = (healthResults, orgUser) => {
  const res = healthResults[getVirtualUserEmail(orgUser.userId).toLowerCase()];
  return res?.isError && (res?.reason?.match(/invalid user/i));
};

/**
 * Selector to determine any of the given host attendees have uninitialized errors
 * @param {*} state
 * @param {OrgUser[]} members
 * @return {boolean}
 */
export const selectAnyMembersHaveUninitializedError = (state, members) => {
  const orgUsers = members || [];
  const connHealthState = state.hostConnectionHealthState.healthResults;
  const hasLicenseError = orgUsers.some((orgUser) => selectUserHasUninitializedErr(connHealthState, orgUser));
  return hasLicenseError;
};

/**
 * Selector to determine any of the given host attendees have validation errors
 * @param {*} state
 * @param {HostAttendee[]} attendees
 * @return {boolean}
 */
export const selectAnyHostsHaveError = (state, attendees) => {
  const hostAttendees = attendees || [];
  const connHealthState = state.hostConnectionHealthState.healthResults;
  const hasHostsValidationErr = hostAttendees.some(
    (hostVal) => connHealthState[hostVal.email.toLowerCase()]?.isError &&
    (hostVal?.host === true || hostVal?.host === undefined));
  return hasHostsValidationErr;
};

export const validateUserConnections = createAsyncThunk(
  'hostConnections/validationConnections',
  async (emails, thunkAPI) => {
    const response = await validateHostConnections(emails);
    return response;
  }
);

/**
 * Gather cached host connection health results for the given emails
 */
export const validateUserConnectionsWithCache = createAsyncThunk(
  'hostConnections/validationMissingConnections',
  async (emails, thunkAPI) => {
    const connHealthState = thunkAPI.getState().hostConnectionHealthState.healthResults;
    /** @type {ConnValidationError[]} */
    const healthResp = [];
    // const missingDataEmails = [];
    emails.forEach((email) => {
      const healthResult = connHealthState[email.toLowerCase()];
      if (!healthResult) {
        // missingDataEmails.push(email);
      } else {
        healthResp.push({
          error: healthResult.isError ? healthResult.reason : undefined,
          user: email.toLowerCase(),
        });
      }
    });
    return healthResp;
    // if (!missingDataEmails.length) {
    //   return healthResp;
    // }
    // const response = await validateHostConnections(missingDataEmails);
    // healthResp.push(...response);
    // return healthResp;
  }
);

export const hostConnectionHealthStore = createSlice({
  name: 'HostConnectionHealthStore',
  initialState,
  reducers: {
    /**
     * To update connection validation store when server returns validateApptAttendees data
     *  via post/patch appt request error response
     * @param {*} state
     * @param {*} action
     * @param {ConnValidationError[]} action.payload
     */
    setHealthResults: (state, action) => {
      const emailResults = action.payload;
      const normalizedResults = emailResults.map((u) => ({
        user: u.user.toLowerCase(),
        error: u.error,
      }));

      normalizedResults.forEach(({error, user}) => {
        if (error) {
          state.healthResults[user] = {
            isError: Boolean(error),
            reason: error,
          };
        } else {
          state.healthResults[user] = {
            isError: false,
            reason: '',
          };
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateUserConnections.fulfilled, (state, action) => {
      // Upsert error entries
      const queriedEmails = action.meta.arg;
      const emailResults = action.payload;

      console.log(queriedEmails, action);
      const normalizedResults = emailResults.map((u) => ({
        user: u.user.toLowerCase(),
        error: u.error,
        boundEmail: u.email,
      }));

      queriedEmails.forEach((email) => {
        const error = normalizedResults.find((userError) => userError.user === email.toLowerCase());
        state.boundEmail[email.toLowerCase()] = error?.boundEmail;
        if (error) {
          state.healthResults[error.user] = {
            isError: Boolean(error.error),
            reason: error.error,
          };
        } else {
          state.healthResults[email.toLowerCase()] = {
            isError: false,
            reason: '',
          };
        }
      });

      state.isLoading = false;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(validateUserConnections.pending, (state, action) => {
      state.isLoading = true;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(validateUserConnections.rejected, (state, action) => {
      state.isLoading = false;
      state.errorState = {
        isError: true,
        reason: '',
      };
    });

    // builder.addCase(validateUserConnectionsWithCache.fulfilled, (state, action) => {
    //   // Upsert error entries
    //   const queriedEmails = action.meta.arg;
    //   const emailResults = action.payload;

    //   const normalizedResults = emailResults.map((u) => ({
    //     user: u.user.toLowerCase(),
    //     error: u.error,
    //   }));

    //   queriedEmails.forEach((email) => {
    //     const error = normalizedResults.find((userError) => userError.user === email.toLowerCase());
    //     if (error) {
    //       state.healthResults[error.user] = {
    //         isError: Boolean(error.error),
    //         reason: error.error,
    //       };
    //     } else {
    //       state.healthResults[email.toLowerCase()] = {
    //         isError: false,
    //         reason: '',
    //       };
    //     }
    //   });

    //   state.isLoading = false;
    //   state.errorState = {
    //     isError: false,
    //     reason: '',
    //   };
    // });
    // builder.addCase(validateUserConnectionsWithCache.pending, (state, action) => {
    //   state.isLoading = true;
    //   state.errorState = {
    //     isError: false,
    //     reason: '',
    //   };
    // });
    // builder.addCase(validateUserConnectionsWithCache.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.errorState = {
    //     isError: true,
    //     reason: '',
    //   };
    // });
  },
});
export const {setHealthResults} = hostConnectionHealthStore.actions;
export default hostConnectionHealthStore.reducer;
